import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Fade, Hidden } from '@mui/material';
import { ThunkDispatch } from 'redux-thunk';
import { showModals } from 'actions/auth';
import { EezyButton } from 'components/Buttons';
import { IBriefInvoice, IIncomeSummary } from '../../../../shared/src/types/invoice';
import { List } from '../../components/layout/List';
import { EmptyListPlaceholder } from 'components/EmptyListPlaceholder';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import InvoiceListItem from 'components/InvoiceListItem';
import { MoveButtonUpToBar, MoveFeedbackButtonUpToBar } from 'components/layout/MoveButtonUpToBar';
import { UniqueTitle, PSmall } from 'components/textElements';
import { COLOR_BLUM, COLOR_LILA, SCREEN_L, SCREEN_S } from 'styles/variables';
import { currentMonth, currentYear, formatCents, isMobile } from 'utils';
import { useSelectedListItem } from 'utils/hooks';
import {
    divideInvoices,
    filterInvoiceList,
    filterInvoiceListByRecipient,
    sortInvoices,
} from 'utils/invoice/invoiceLogic';
import {
    GET_AAVA_INVOICES,
    GET_INCOME,
    GET_INVOICES,
} from '../dashboard/queries';
import { useTranslation } from 'react-i18next';
import { TextDivider } from 'components/TextDivider';
import { IRootState } from 'reducers';
import { FeedbackButton } from '../nav/drawer.styles';
import { DashboardCard } from 'components/cards/DashboardCard';
import InvoiceSearch from 'containers/invoice/InvoiceSearch';
import LoadingSpinner from 'components/Loading';
import FeedbackImg from '../../assets/images/feedback-v2.svg';
import ContainerMobileScrollTop from 'components/ui/ContainerMobileScrollTop';
import ContainerScrollTop from 'components/ui/ContainerScrollTop';
import Show from 'components/ui/Show';

const ITEM_DELAY = 20;
const FADE_TIMEOUT = 200;
const SEARCH_MIN = 0;
const MIN_ITEMS_TO_SHOW_SCROLL = 12;
const AAVA_INVOICE_UPDATE_INTERVAL = 5 * 60 * 1000; // 5 minutes

const Wrapper = styled.div<{ showScroll?: boolean }>`
    padding-right: ${(props) => (props.showScroll ? '15px' : '0')};
`;
Wrapper.displayName = 'Wrapper';

const PendingPaymentInvoice = () => {
    const { t } = useTranslation();

    const { data } = useQuery(GET_INCOME, {
        variables: { year: currentYear(), month: currentMonth() },
    });
    const incomeData: IIncomeSummary = data?.income;

    return (
        <div className="py-6 flex gap-2.5 items-center" style={{ color: '#351F65' }}>
            <Icon icon={['far', 'clock']} />
            <div>{t('dashboard.invoices.pending-payment')}:</div>
            <b className="text-xl">{formatCents(incomeData?.incomeOpen ?? 0, true)} €</b>
        </div>
    );
};

type InvoiceListItems = {
    items: IBriefInvoice[];
    selectedId: number | undefined;
    onMouseOver?: (item: IBriefInvoice, index: number) => void;
};

const InvoiceListItems: React.FC<InvoiceListItems> = (props: InvoiceListItems) => {
    const language = useSelector((state: IRootState) => state.user.language);
    const navigate = useNavigate();

    const sendBriefInvoice = (invoiceBrief: IBriefInvoice) => {
        const url = invoiceBrief.isGroupInvoice ? `/group/view/${invoiceBrief.id}` : `/invoices/view/${invoiceBrief.id}`;
        if (invoiceBrief.status !== 'sending_pending') {
            navigate(url);
        }
    }

    const items = props.items.map((invoiceBrief) => ({
        data: invoiceBrief,
        selected: props.selectedId === invoiceBrief.id,
        onClick: () => sendBriefInvoice(invoiceBrief),
    }))

    return (
        <div>
            <ul>
                {items.map((item, index) => (
                    <li
                        className={item.selected ? 'selected' : ''}
                        key={`${index}-invoice-${item.data.id}`}
                        tabIndex={0}
                        onClick={item.onClick}
                        onKeyPress={item.onClick}
                    >
                        <Fade in={true} timeout={FADE_TIMEOUT}>
                            <div onMouseOver={() => props.onMouseOver?.(item.data, index)}>
                                <InvoiceListItem
                                    invoiceBrief={item.data}
                                    key={item.data.id}
                                    language={language}
                                    selected={item.selected}
                                />
                            </div>
                        </Fade>
                    </li>
                ))}
            </ul>
        </div>
    );
};

type InvoiceListProps = {

}

const InvoiceList: React.FC<InvoiceListProps> = (props: InvoiceListProps) => {
    const DEFAULT_VISIBLE_ITEMS_LENGTH = window.innerWidth >= SCREEN_L ? 200 : 5;
    const el = useRef<any>(null);

    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const searchQuery = useSelector((state: IRootState) => state.invoice.searchQuery);
    const searchRecipientId = useSelector((state: IRootState) => state.invoice.searchRecipientId);

    const [allInvoices, setAllInvoices] = useState<IBriefInvoice[]>([]);
    const [topInvoices, setTopInvoices] = useState<IBriefInvoice[]>([]);
    const [bottomInvoices, setBottomInvoices] = useState<IBriefInvoice[]>([]);
    const [initialLoadDone, setInitialLoadDone] = useState<boolean>(false);
    const [visibleTopLength, setVisibleTopLength] = useState(DEFAULT_VISIBLE_ITEMS_LENGTH);
    const [visibleBottomLength, setVisibleBottomLength] = useState(DEFAULT_VISIBLE_ITEMS_LENGTH);

    const selectedId = useSelectedListItem();

    const scrollRef = useRef<HTMLSpanElement>(null);

    const showScroll = !isMobile() && topInvoices.length + bottomInvoices.length >= MIN_ITEMS_TO_SHOW_SCROLL;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const visibleTopLoadMoreButton = () => {
        if (!allInvoices) {
            return false
        }

        return visibleTopLength < topInvoices.length
    };

    const visibleBottomLoadMoreButton = () => {
        if (!allInvoices) {
            return false
        }

        return visibleBottomLength < bottomInvoices.length
    };

    const displayTopInvoices = () => {
        return topInvoices.slice(0, visibleTopLength);
    };

    const displayBottomInvoices = () => {
        return bottomInvoices.slice(0, visibleBottomLength);
    };

    const loadMoreTopInvoices = () => {
        setVisibleTopLength(visibleTopLength + DEFAULT_VISIBLE_ITEMS_LENGTH);
    }

    const loadMoreBottomInvoices = () => {
        setVisibleBottomLength(visibleBottomLength + DEFAULT_VISIBLE_ITEMS_LENGTH);
    }

    const [fetchAllInvoices, { loading: isLoadingAllInvoices }] = useLazyQuery(
        GET_INVOICES,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    const [fetchLatestAavaInvoices] = useLazyQuery(
        GET_AAVA_INVOICES,
        {
            errorPolicy: 'all',
            notifyOnNetworkStatusChange: true,
            variables: {
                page: { offset: 0, pageSize: 10 },
            },
        },
    );

    const loadAllInvoices = async () => {
        try {
            let { data } = await fetchAllInvoices()
            const sortedAllInvoices = sortInvoices(data.allInvoices.items);
            const { topInvoices, bottomInvoices } = divideInvoices(sortedAllInvoices);

            setAllInvoices(sortedAllInvoices)
            setTopInvoices(topInvoices);
            setBottomInvoices(bottomInvoices);
            setInitialLoadDone(true);
        } catch (e) {
            console.error(e)
        }
    }

    const checkForNewInvoices = async () => {
        const { data: { aavaInvoices } } = await fetchLatestAavaInvoices()

        const hasDifferences = allInvoices.find(i => {
            const match = aavaInvoices.items.find((x: any) => x.id == i.id)

            if (!match) {
                return true
            }

            return match.updateDate != i.updateDate
        })

        if (hasDifferences) {
            await loadAllInvoices()
        }
    }

    // const shouldAnkkaInvoicesRefetch = useSelector(
    //     (state: IRootState) => state.refetch.queries['GET_ANKKA_INVOICES'],
    // );

    // const shouldAavaInvoicesRefetch = useSelector(
    //     (state: IRootState) => state.refetch.queries['GET_AAVA_INVOICES'],
    // );

    const handleNewInvoiceClick = () => {
        if (window.innerWidth >= SCREEN_S) {
            dispatch(showModals(['CREATION_METHOD']));
        } else {
            navigate('/invoices/new');
        }
    };

    const handleHelpClick = () => {
        navigate('/notifications');
    };

    // useEffect(() => {
    //     if (shouldAnkkaInvoicesRefetch) {
    //         ankkaInvoicesRefetch().finally(() => {
    //             dispatch(resetRefetch('GET_ANKKA_INVOICES'));
    //         });
    //     }
    // }, [shouldAnkkaInvoicesRefetch, ankkaInvoicesRefetch, dispatch]);

    // useEffect(() => {
    //     if (shouldAavaInvoicesRefetch) {
    //         aavaInvoicesRefetch().finally(() => {
    //             dispatch(resetRefetch('GET_AAVA_INVOICES'));
    //         });
    //     }
    // }, [shouldAavaInvoicesRefetch, aavaInvoicesRefetch, dispatch]);

    useEffect(() => {
        if (allInvoices) {
            let searchResults;
            if (searchRecipientId) {
                searchResults = filterInvoiceListByRecipient(searchRecipientId.toString(), allInvoices);
            } else {
                const filter = (searchQ: string) => {
                    if (searchQ.length >= SEARCH_MIN) {
                        return filterInvoiceList(searchQ, allInvoices);
                    }
                };

                searchResults = filter(searchQuery || '');
            }

            if (searchResults) {
                const { topInvoices, bottomInvoices } = searchResults;
                setTopInvoices(topInvoices);
                setBottomInvoices(bottomInvoices);
            }
        }
    }, [allInvoices, searchQuery, searchRecipientId]);

    const onMouseOverListItem = (item: IBriefInvoice, index: number) => {
        // if (index < 5) {
        //     props.client
        //         .query({
        //             query: GET_INVOICE,
        //             variables: {
        //                 id: item.data.id,
        //                 isGroupInvoice: item.data.isGroupInvoice,
        //             },
        //         })
        //         .catch(() => {
        //             return;
        //         });
        // }
    }

    // Loop Interval to constantly fetch new invoices
    useEffect(() => {
        const interval = setInterval(() => {
            checkForNewInvoices();
        }, AAVA_INVOICE_UPDATE_INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [allInvoices]);

    useEffect(() => {
        loadAllInvoices()
    }, [])

    return (
        <DashboardCard>
            <Wrapper showScroll={showScroll} className="xl:relative w-full">
                <Hidden mdUp>
                    <MoveFeedbackButtonUpToBar>
                        <FeedbackButton
                            className="feedback-button-xs"
                            onClick={() => {
                                dispatch(showModals(['FEEDBACK']));
                            }}
                            left={false}
                        >
                            <img src={FeedbackImg} alt="Feedback" />
                        </FeedbackButton>
                    </MoveFeedbackButtonUpToBar>
                    <MoveButtonUpToBar>
                        <EezyButton
                            className="help-button-xs"
                            color="purple"
                            dark
                            onClick={handleHelpClick}
                            style={{ border: `1px solid ${COLOR_LILA}` }}
                        >
                            {t('dashboard.frontpage-button')}
                        </EezyButton>
                    </MoveButtonUpToBar>
                </Hidden>
                <Fade in={true} timeout={FADE_TIMEOUT}>
                    <Flex spread center style={{ paddingBottom: '27px' }}>
                        <UniqueTitle>{t('invoice.invoices')}</UniqueTitle>
                        <EezyButton dark color="purple" className="v2-btn" onClick={handleNewInvoiceClick}>
                            <Icon color="white" icon={['far', 'plus']} /> {t('invoice.new-invoice')}
                        </EezyButton>
                    </Flex>
                </Fade>

                {isLoadingAllInvoices && !initialLoadDone ? (
                    <Flex justifyCenter style={{ padding: '25px 0' }}>
                        <LoadingSpinner color={COLOR_BLUM} />
                    </Flex>
                ) : (
                    <>
                        <Fade in={true} timeout={FADE_TIMEOUT} style={{ transitionDelay: '100ms' }}>
                            {allInvoices.length > 0 ? (
                                <div className="mb-1">
                                    <InvoiceSearch />
                                </div>
                            ) : (
                                <div>
                                    <EmptyListPlaceholder text={t('invoice.empty-list-text')} />
                                </div>
                            )}
                        </Fade>
                        <PendingPaymentInvoice />
                    </>
                )}

                <List ref={el} className={showScroll ? 'show-scroll' : 'hide-scroll'}>
                    <Hidden mdDown>
                        <span ref={scrollRef} />
                    </Hidden>
                    {/* Top Invoices */}
                    <InvoiceListItems
                        items={displayTopInvoices()}
                        selectedId={selectedId}
                        onMouseOver={onMouseOverListItem}
                    />
                    <Show when={visibleTopLoadMoreButton()}>
                        <div className='mt-10'>
                            <EezyButton
                                color="purple"
                                transparent
                                iconAlignment="right"
                                onClick={loadMoreTopInvoices}
                                className="v2-btn w-full border-0"
                            >
                                {isLoadingAllInvoices ? (
                                    <LoadingSpinner size="1em" />
                                ) : (
                                    t('dashboard.invoices.load-more-button')
                                )}
                                <Icon icon={['far', 'arrow-down']} />
                            </EezyButton>
                        </div>
                    </Show>
                    {/* Bottom Invoices */}
                    <Show when={displayBottomInvoices().length > 0}>
                        <Fade
                            in={true}
                            timeout={FADE_TIMEOUT}
                            style={{ transitionDelay: `${ITEM_DELAY * topInvoices.length}ms` }}
                        >
                            <div>
                                <TextDivider>
                                    <PSmall color={COLOR_BLUM}>{t('invoice.invoices-ready')}</PSmall>
                                </TextDivider>
                            </div>
                        </Fade>
                    </Show>
                    <InvoiceListItems
                        items={displayBottomInvoices()}
                        selectedId={selectedId}
                        onMouseOver={onMouseOverListItem}
                    />
                    <Show when={visibleBottomLoadMoreButton()}>
                        <div className='mt-10'>
                            <EezyButton
                                color="purple"
                                transparent
                                iconAlignment="right"
                                onClick={loadMoreBottomInvoices}
                                className="v2-btn w-full border-0"
                            >
                                {isLoadingAllInvoices ? (
                                    <LoadingSpinner size="1em" />
                                ) : (
                                    t('dashboard.invoices.load-more-button')
                                )}
                                <Icon icon={['far', 'arrow-down']} />
                            </EezyButton>
                        </div>
                    </Show>
                </List>
                <Show when={el.current}>
                    <Show when={window.innerWidth >= SCREEN_L}>
                        <ContainerScrollTop container={el.current} key={topInvoices.length} />
                    </Show>
                    <Show when={(visibleTopLength > DEFAULT_VISIBLE_ITEMS_LENGTH) || (visibleBottomLength > DEFAULT_VISIBLE_ITEMS_LENGTH)}>
                        <ContainerMobileScrollTop
                            container={el.current}
                            key={visibleTopLength + '-' + visibleBottomLength}
                            topOffset={148}
                        />
                    </Show>
                </Show>
            </Wrapper>
        </DashboardCard>
    );
};

export default InvoiceList;
