import { createRef, useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    ApolloError,
    ApolloQueryResult,
    useApolloClient,
    useLazyQuery,
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import { Hidden } from '@mui/material';
import throttle from 'lodash.throttle';
import { toast } from 'react-toastify';

import { FillType, IBriefInvoice, IInvoice, } from '../../../../shared/src/types/invoice';
import { IUserBasicData, UiLanguage } from '../../../../shared/src/types/user';
import { alertAction, IAlertAction, IConfirmAction } from 'actions/app';
import { EezyButton } from 'components/Buttons';
import { Line2 } from 'components/Lines';
import InformationModal from '../../components/modals/InformationModal';
import ParticipantsModal from '../../components/modals/ParticipantsModal';
import { AttachmentRibbon, CostRibbon, RibbonContainerInvoiceEdit } from 'components/Ribbon';
import SwitchButton from '../../components/Switch';
import { Tools, ToolsHeader, ToolsRow } from 'components/TogglingTools';
import { getErrors } from 'utils/apolloErrors';
import { canCostInvoiceBeEditedEvenInvoiceIsPaid } from 'utils/costs/costLogic';
import { InvoiceBackground, InvoiceUtilsArea } from 'styles/invoiceStyles';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_WHITE_WALKER } from 'styles/variables';
import {
    CopyInvoiceMessageEnum,
    costInvoiceNotSent,
    duedateReferences,
    getById,
    getTemplate,
    isEditable,
    isGroupInvoiceCoworker,
    isIncompleteAavaInvoice,
    isTurnedBackAndNotEditable,
    showCostInvoiceTurnbackReason,
} from '../../utils/invoice/invoiceLogic';
import { invoiceValidator } from '../../utils/invoice/validators';
import { IDocument } from '../../utils/user/userUtils';
import { formatValidationResult } from '../../utils/validation';
import CostDocument from '../costs/CostDocument';
import { DELETE_ATTACHMENT } from '../costs/queries';
import CREATE_EMPTY_COSTINVOICE from '../costs/queries/createEmptyCostInvoice';
import { GET_INVOICES } from '../dashboard/queries';
import PurpleBackground from '../nav/PurpleBackground';
import { ChatTools } from './ChatTools';
import { CostManualDesktop, CostManualMobile } from './CostManual';
import { DeleteInvoice } from './DeleteInvoice';
import { getFillhelper } from './fillHelpers/utils';
import FreeFillActions from './FreeFillActions';
import GroupInvoiceInfoBox from './GroupInvoiceInfoBox';
import InvoiceAttachmentAdd from './InvoiceAttachmentAdd';
import InvoiceAttachmentList from './InvoiceAttachmentList';
import InvoiceAttachments from './InvoiceAttachments';
import StatusBar from './InvoiceBar';
import InvoiceCompletedPanel from './InvoiceCompletedPanel';
import InvoiceDocument from './InvoiceDocument';
import InvoiceErrors from './InvoiceErrors';
import InvoiceFillProgressStatus from './InvoiceFillProgressStatus';
import InvoiceFillWizard from './InvoiceFillWizard';
import InvoiceSalary from './InvoiceSalary';
import InvoiceSend from './InvoiceSend';
import InvoiceSendModal from './InvoiceSendModal';
import InvoiceTypeEdit from './InvoiceTypeEdit';
import InvoiceTypeModal from './InvoiceTypeModal';
import {
    CostInvoiceSendingReminderPostIt,
    CostTurnbackReasonPostIt,
    InvoiceTurnbackReasonPostIt,
} from './postits';
import {
    COPY_INVOICE,
    GET_INVOICE,
    INVOICE_STATUS_SUBSCRIPTION,
    RETURN_INVOICE,
    RETURN_TURNED_BACK_INVOICE,
    UPDATE_INVOICE_ITEM,
} from './queries';
import {
    INVOICE_COPY_META,
    INVOICE_ERRORS_VISIBLE,
    SELECTED_FILL_HELPER,
} from './queries/invoiceStateQueries';
import VALIDATE_INVOICE from './queries/validateInvoice';
import { humanReadableInvoiceErrors } from 'utils/invoice/validationErrorMessages';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface IInvoiceProps {
    alertAction: (payload: IAlertAction) => void;
    confirmAction: (payload: IConfirmAction) => void;
    expressErrors?: string[];
    fillType?: FillType;
    invoice?: IInvoice;
    isNew?: boolean;
    lang: UiLanguage;
    loadingCostInvoice?: boolean;
    loadingDocument?: boolean;
    loadingRecipient?: boolean;
    loadingUpdate?: boolean;
    metaData?: any;
    routeState?: any;
    onCostInvoiceSend?: () => void;
    onUpdate?: (o: object) => void;
    onSend?: (messageToEezy: string) => void;
    onValidationError?: (e: ApolloError) => void;
    recruitmentCode: string;
    refetchInvoice?: () => Promise<ApolloQueryResult<any>>;
    showModals: (types: string[]) => void;
    sideErrors?: string[];
    userData?: IUserBasicData;
    userId: number;
    userName: string;
    hasContract?: boolean;
}

const storageKey = 'pagepos';

const InvoiceView = (props: IInvoiceProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { fillType, invoice, isNew, loadingCostInvoice, loadingDocument, loadingUpdate, userData } = props;
    const [typeModalOpen, setTypeModalOpen] = useState(false);
    const [sendModalOpen, setSendModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [costInvoiceNotEditableModalOpen, setCostInvoiceNotEditableModalOpen] = useState(false);
    const [costInvoiceNotEditablePaidEarlierModalOpen, setCostInvoiceNotEditablePaidEarlierModalOpen] =
        useState(false);

    const [participantsModalOpen, setParticipantsModalOpen] = useState(false);

    const [attachmentScrollRefs, setAttachmentScrollRefs] = useState<any>([]);
    const [scrollPos, setScrollPos] = useState(0);

    const files = invoice?.files;
    useEffect(() => {
        // add or remove refs
        setAttachmentScrollRefs((refs: any) =>
            Array(files?.length || 0)
                .fill(null)
                .map((_, i) => refs[i] || createRef()),
        );
    }, [files]);

    const scrollListener = throttle(
        () => {
            if (sessionStorage && invoice?.id) {
                const jsonStr = sessionStorage.getItem(storageKey);
                const json = jsonStr ? JSON.parse(jsonStr) : {};
                json[invoice.id] = window.scrollY;
                sessionStorage.setItem(storageKey, JSON.stringify(json));
            }
        },
        1000,
        { leading: false },
    );

    useEffect(() => {
        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, [scrollListener]);

    useEffect(() => {
        let initialPosition = 0;
        if (sessionStorage && invoice?.id) {
            const jsonStr = sessionStorage.getItem(storageKey);
            const json = jsonStr ? JSON.parse(jsonStr) : {};
            initialPosition = json[invoice.id] || 0;
        }
        if (initialPosition > 0) {
            setScrollPos(initialPosition);
        }
    }, [invoice?.id]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, scrollPos);
        }, 500);
    }, [scrollPos]);

    const errorRef = useRef<HTMLSpanElement>(null);
    const costInvoiceScrollRef = useRef<HTMLSpanElement>(null);

    const editable = isEditable(invoice, isNew);
    const groupInvoiceCoworker = isGroupInvoiceCoworker(props.userId, invoice);

    const fillhelperOn = false;

    const { data: localData } = useQuery(SELECTED_FILL_HELPER);
    const FillHelperContent = getFillhelper(
        localData?.fillHelper,
        editable,
        invoice?.id,
        props.showModals,
        fillhelperOn,
    );
    const { data: allInvoicesData } = useQuery(GET_INVOICES, {
        errorPolicy: 'all',
        pollInterval: 5 * 60 * 1000,
    });

    const invoiceBrief: IBriefInvoice | undefined = getById(invoice?.id, allInvoicesData?.allInvoices?.items);

    const { data: invoiceSendingStatusData } = useSubscription(INVOICE_STATUS_SUBSCRIPTION);

    const [validateInvoice, { loading: validationLoading }] = useLazyQuery(VALIDATE_INVOICE, {
        fetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setSendModalOpen(true);
        },
        onError: (error) => {
            if (props.onValidationError) {
                props.onValidationError(error);
            }
            scrollToError();
        },
    });

    const [checkSalaryPaymentStatusForInvoiceAndSend] = useLazyQuery(GET_INVOICE, {
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            const { invoice: resInvoice } = data;
            if (
                resInvoice &&
                resInvoice.status === 'paid' &&
                !canCostInvoiceBeEditedEvenInvoiceIsPaid(resInvoice)
            ) {
                setCostInvoiceNotEditableModalOpen(true);
            } else {
                if (props.onCostInvoiceSend) {
                    props.onCostInvoiceSend();
                }
            }
        },
    });

    const [checkSalaryPaymentStatusForCreateCostInvoice] = useLazyQuery(GET_INVOICE, {
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            const { invoice: resInvoice } = data;
            if (
                resInvoice &&
                resInvoice.status === 'paid' &&
                !canCostInvoiceBeEditedEvenInvoiceIsPaid(resInvoice)
            ) {
                setCostInvoiceNotEditablePaidEarlierModalOpen(true);
            } else {
                createEmptyCostInvoice();
            }
        },
    });

    const fillHelperOpen =
        localData?.fillHelper && localData?.fillHelper?.type !== null && FillHelperContent !== null;

    const [updateInvoiceItem, { loading: loadingItemUpdate }] = useMutation(UPDATE_INVOICE_ITEM, {
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            if (errors?.mustAccept) {
                props.showModals(errors?.mustAccept.map((t: IDocument) => t.document));
            } else if (errors?.error === 'BAD_USER_INPUT') {
                toast.error(t('errors.negativeInvoiceItem'));
            } else {
                toast.error(t('errors.general'));
            }
        },
        update(cache, { data: { updateInvoiceItem: invoiceData } }) {
            const invoiceCache: {
                invoice: IInvoice;
            } | null = cache.readQuery({
                query: GET_INVOICE,
                variables: { id: invoice?.id },
            });

            if (invoiceData.totalVats && invoiceCache) {
                cache.writeQuery({
                    data: {
                        invoice: {
                            ...invoiceCache.invoice,
                            invoiceItems: invoiceData.invoiceItems,
                            total: invoiceData.total,
                            totalVats: invoiceData.totalVats,
                            totalWithVat: invoiceData.totalWithVat,
                        },
                    },
                    query: GET_INVOICE,
                    variables: { id: invoice?.id },
                });
            }
        },
    });

    const [copyInvoice, { loading: copyLoading }] = useMutation(COPY_INVOICE, {
        onCompleted: ({ copyInvoice: newInvoice }) => {
            const messages: CopyInvoiceMessageEnum[] = newInvoice?.meta?.messages ?? []

            messages.forEach(i => {
                switch (i) {
                    case CopyInvoiceMessageEnum.INVOICING_VAT_CHANGED:
                        props.alertAction({
                            id: 'copy-vat-changed',
                            onAction: () => { },
                            texts: {
                                paragraph: 'errors.copy.vat-changed',
                                submit: 'form.understand',
                                title: '',
                            },
                        });
                        break;
                }
            })

            const copyid = newInvoice?.invoice.id;
            return navigate(`/invoices/${copyid}`);
        },
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            if (errors?.mustAccept) {
                props.showModals(errors?.mustAccept.map((t: IDocument) => t.document));
            } else {
                toast.error(t('errors.general'));
            }
        },
        update(cache, { data: { copyInvoice: invoiceCopy } }) {
            const newInvoice = invoiceCopy?.invoice;
            const metaData = invoiceCopy?.meta;

            if (newInvoice) {
                cache.writeQuery({
                    data: {
                        invoice: newInvoice,
                    },
                    query: GET_INVOICE,
                    variables: { id: newInvoice.id },
                });
                if (metaData) {
                    client.writeQuery({
                        data: {
                            copyMeta: {
                                __typename: 'MetaMessages',
                                id: newInvoice.id,
                                messages: metaData.messages,
                            },
                        },
                        query: INVOICE_COPY_META,
                    });
                }
            }
        },
        variables: { invoiceId: invoice?.id },
    });

    const [createEmptyCostInvoice] = useMutation(CREATE_EMPTY_COSTINVOICE, {
        onCompleted: () => scrollToCostInvoice(),
        onError: () => {
            toast.error(t('errors.general'));
        },
        update(cache, { data: { createEmptyCostInvoice: createdEmptyCostInvoice } }) {
            if (createdEmptyCostInvoice) {
                cache.writeQuery({
                    data: {
                        invoice: {
                            ...invoice,
                            costInvoice: createdEmptyCostInvoice,
                        },
                    },
                    query: GET_INVOICE,
                    variables: { id: createdEmptyCostInvoice.invoiceId },
                });
            }
        },
        variables: { invoiceId: invoice?.id },
    });

    const [returnInvoice] = useMutation(RETURN_INVOICE, {
        onCompleted: ({ returnInvoice: newInvoice }) => {
            navigate(`/invoices/${newInvoice.id}`);
        },
        onError: () => toast.error(t('errors.general')),
        refetchQueries: () => [{ query: GET_INVOICES }],
        variables: { id: invoice?.id },
    });

    const [returnTurnedBackInvoice] = useMutation(RETURN_TURNED_BACK_INVOICE, {
        onCompleted: ({ returnTurnedBackInvoice: newInvoice }) => {
            navigate(`/invoices/${newInvoice.id}`);
        },
        onError: () => toast.error(t('errors.general')),
        refetchQueries: () => [{ query: GET_INVOICES }],
        variables: { id: invoice?.id },
    });

    const [showProgressBar, setShowProgressBar] = useState(false);

    useEffect(() => {
        setShowProgressBar(isEditable(invoice, isNew) || invoiceSendingStatusData?.invoiceStatus?.originalId);
    }, [invoice, isNew, invoiceSendingStatusData]);

    useEffect(() => {
        if (invoiceBrief?.status !== invoice?.status && props.refetchInvoice) {
            // refetch ivoice if status is updated (in aava)
            props.refetchInvoice();
        }
    }, [invoiceBrief]);

    const selectedTemplate = getTemplate(invoice?.template);

    const client = useApolloClient();

    const handleInvoiceUpdate = (o: object) => {
        if (props.onUpdate) {
            props.onUpdate(o);
        }
    };

    const handleSend = (messageToEezy: string) => {
        if (props.onSend) {
            props.onSend(messageToEezy);
        }
    };

    const handleCostInvoiceSend = () => {
        // If invoice is paid check salary payment status
        if (props.invoice?.status === 'paid') {
            checkSalaryPaymentStatusForInvoiceAndSend({
                variables: {
                    id: props.invoice?.id,
                    isGroupInvoice: groupInvoiceCoworker,
                },
            });
        } else if (props.onCostInvoiceSend) {
            props.onCostInvoiceSend();
        }
    };

    const handleCopy = () => {
        if (!copyLoading) {
            toast(t('errors.copy.ongoing'));
            console.log('here')
            copyInvoice();
        }
    };

    const [deleteAttachment, { loading: attachmentDeleteLoading }] = useMutation(DELETE_ATTACHMENT, {
        onCompleted: () => toast(t('general.deleted')),
        onError: () => toast.error('errors.general'),
        refetchQueries: () => [{ query: GET_INVOICE, variables: { id: invoice?.id } }],
    });

    const handleFileRemoval = (index: number) => {
        const file = invoice?.files?.[index];
        if (!file) {
            return;
        }
        props.confirmAction({
            id: 'invoice-attachment-delete',
            onAction: () => deleteAttachment({ variables: { attachmentId: file.id } }),
            texts: {
                submit: 'form.delete',
                title: 'invoice.attachment-delete-confirmation',
            },
        });
    };

    const scrollToError = useCallback(() => {
        if (errorRef.current) {
            errorRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [errorRef]);

    const scrollToAttachmentAt = useCallback(
        (index: number) => {
            if (attachmentScrollRefs[index].current) {
                attachmentScrollRefs[index].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        },
        [attachmentScrollRefs],
    );

    const scrollToCostInvoice = useCallback(() => {
        if (costInvoiceScrollRef.current) {
            costInvoiceScrollRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [costInvoiceScrollRef]);

    const handleCreateTempCostInvoice = () => {
        if (invoice && !invoice.costInvoice) {
            // If invoice is paid chech salary payment status
            if (props.invoice?.status === 'paid') {
                checkSalaryPaymentStatusForCreateCostInvoice({
                    variables: {
                        id: props.invoice?.id,
                    },
                });
            } else if (props.onCostInvoiceSend) {
                createEmptyCostInvoice();
            }
        } else {
            scrollToCostInvoice();
        }
    };
    const handleAttachmentClicked = (index: number) => {
        scrollToAttachmentAt(index);
    };

    const handleValidation = () => {
        if (props.onSend) {
            const errors = formatValidationResult(
                invoiceValidator.validate({
                    ...invoice,
                    ...duedateReferences(invoice?.dispatchDate),
                }),
            );
            if (errors) {
                const errorMessages = humanReadableInvoiceErrors(errors);
                const filteredErrors = errorMessages.filter(Boolean);
                toast.error(filteredErrors.length ? filteredErrors[0] : t('errors.invoice-data-missing'));
                client.writeQuery({
                    data: { invoiceErrorsVisible: true },
                    query: INVOICE_ERRORS_VISIBLE,
                });

                // TODO: once we are sure every case is handled, we can remove this.
                if (errorMessages.length !== filteredErrors.length) {
                    // eslint-disable-next-line no-console
                    console.log('Untranslated error', errors);
                }
            } else {
                validateInvoice({
                    variables: {
                        id: invoice?.id,
                    },
                });
                client.writeQuery({
                    data: { invoiceErrorsVisible: false },
                    query: INVOICE_ERRORS_VISIBLE,
                });
            }
        }
    };

    const handleEditTurnedBack = () => {
        props.confirmAction({
            id: `edit-turned-back-${invoice?.id}`,
            onAction: () => {
                toast(t('invoice.edit-modal-turned-back.loading'));
                returnTurnedBackInvoice();
            },
            texts: {
                paragraph: 'invoice.edit-modal-turned-back.paragraph',
                paragraph2: 'invoice.edit-modal-turned-back.paragraph2',
                submit: 'invoice.edit-modal-turned-back.submit',
                title: 'invoice.edit-modal-turned-back.title',
            },
        });
    };

    const handleEditIncomplete = () => {
        props.confirmAction({
            id: `edit-incomplete-${invoice?.id}`,
            onAction: () => {
                toast(t('invoice.edit-modal-incomplete.loading'));
                returnInvoice();
            },
            texts: {
                paragraph: 'invoice.edit-modal-incomplete.paragraph',
                paragraph2: 'invoice.edit-modal-incomplete.paragraph2',
                submit: 'invoice.edit-modal-incomplete.submit',
                title: 'invoice.edit-modal-incomplete.title',
            },
        });
    };

    const handleEdit = isTurnedBackAndNotEditable(invoice)
        ? handleEditTurnedBack
        : isIncompleteAavaInvoice(invoice) ||
            invoice?.status === 'unaccepted' ||
            invoice?.status === 'waiting_for_dispatch'
            ? handleEditIncomplete
            : undefined;

    const handleFillTypeChange = (type: FillType) => {
        const newType = type === 'free' ? 'guided' : 'free';
        handleInvoiceUpdate({ fillType: newType });
    };

    const FreeFillPanel = () => {
        return (
            <InvoiceUtilsArea>
                <Hidden mdDown>
                    {invoice?.status === 'turned_back' && invoice?.turnBackReason && (
                        <InvoiceTurnbackReasonPostIt editView invoice={invoice} />
                    )}
                    {showCostInvoiceTurnbackReason(invoice?.costInvoice) && (
                        <CostTurnbackReasonPostIt editView invoice={invoice} />
                    )}
                    {invoiceBrief &&
                        costInvoiceNotSent(invoiceBrief) &&
                        (invoice?.status !== 'paid' ||
                            (invoice?.status === 'paid' &&
                                canCostInvoiceBeEditedEvenInvoiceIsPaid(invoice))) && (
                            <CostInvoiceSendingReminderPostIt />
                        )}
                </Hidden>
                <span ref={errorRef} />
                <InvoiceErrors errors={props.sideErrors} titleTransKey={'errors.sending-validation.title'} />
                <InvoiceErrors
                    errors={props.expressErrors}
                    style={{ marginTop: 10 }}
                    titleTransKey={'errors.express.title'}
                />
                <InvoiceErrors
                    errors={props.metaData?.messages}
                    style={{ marginTop: 10 }}
                    titleTransKey={'errors.copy.title'}
                />
                <InvoiceTypeEdit
                    editable={editable}
                    invoice={invoice}
                    selectedTemplate={selectedTemplate}
                    setTypeModalOpen={setTypeModalOpen}
                />
                <Line2 />
                {isTurnedBackAndNotEditable(invoice) && (
                    <ToolsRow
                        buttonTransKey="general.edit"
                        titleTransKey="invoice.edit-invoice"
                        onClick={handleEditTurnedBack}
                    />
                )}
                {isIncompleteAavaInvoice(invoice) && (
                    <ToolsRow
                        buttonTransKey="general.edit"
                        titleTransKey="invoice.edit-invoice"
                        onClick={handleEditIncomplete}
                    />
                )}
                <InvoiceAttachmentAdd
                    editable={editable}
                    refetchInvoice={props.refetchInvoice}
                    invoice={invoice}
                />
                <InvoiceAttachmentList
                    files={invoice?.files}
                    handleAttachmentClicked={scrollToAttachmentAt}
                    handleDeleteFile={handleFileRemoval}
                />
                <Line2 />
                <InvoiceSalary
                    editable={editable}
                    handleAddCostInvoice={handleCreateTempCostInvoice}
                    invoice={invoice}
                    hasContract={props.hasContract}
                />
                <Line2 />
                <FreeFillActions
                    invoice={invoice}
                    loading={loadingDocument || loadingUpdate || loadingItemUpdate}
                    onCopy={handleCopy}
                    onEdit={handleEdit}
                />
                <InvoiceSend
                    disabled={!props.onSend || loadingDocument || loadingUpdate || loadingItemUpdate}
                    onClick={handleValidation}
                    validationLoading={validationLoading}
                />
            </InvoiceUtilsArea>
        );
    };

    const invoiceErrors = formatValidationResult(
        invoiceValidator.validate({
            ...invoice,
            ...duedateReferences(invoice?.dispatchDate),
        }),
    );

    const invoiceFillWizard = (
        <InvoiceFillWizard
            editable={editable}
            expressErrors={props.expressErrors}
            language={props.lang}
            invoice={invoice}
            invoiceErrors={invoiceErrors}
            isCoworkerInvoice={groupInvoiceCoworker || false}
            handleAddCostInvoice={handleCreateTempCostInvoice}
            handleCopy={handleCopy}
            handleCreateTempCostInvoice={handleCreateTempCostInvoice}
            handleEdit={handleEdit}
            handleInvoiceSend={handleValidation}
            handleInvoiceUpdate={handleInvoiceUpdate}
            handleInvoiceItemUpdate={updateInvoiceItem}
            handleAttachmentClicked={handleAttachmentClicked}
            handleAttachmentDelete={handleFileRemoval}
            refetchInvoice={props.refetchInvoice}
            setTypeModalOpen={setTypeModalOpen}
            sideErrors={props.sideErrors}
            template={selectedTemplate}
            hasContract={props.hasContract}
        />
    );

    const invoiceDocument = (
        <InvoiceDocument
            editable={editable}
            invoice={invoice}
            invoiceBrief={invoiceBrief}
            invoiceErrors={invoiceErrors}
            handleInvoiceUpdate={handleInvoiceUpdate}
            handleInvoiceItemUpdate={updateInvoiceItem}
            loadingRecipient={props.loadingRecipient}
            loadingUpdate={loadingUpdate}
            template={selectedTemplate}
            userData={userData}
            fillType={fillType}
            hasContract={props.hasContract}
            handleAddCostInvoice={handleCreateTempCostInvoice}
        >
            {/* Attachment and costinvoice ribbon */}
            <RibbonContainerInvoiceEdit>
                {invoice?.files && invoice?.files?.length > 0 && (
                    <AttachmentRibbon onClick={() => scrollToAttachmentAt(0)}>
                        {t('invoice.attachments-count', {
                            count: invoice?.files.length,
                        })}
                    </AttachmentRibbon>
                )}
                {invoice?.costInvoice && (
                    <CostRibbon onClick={scrollToCostInvoice}>{t('costs.breakdown-short')}</CostRibbon>
                )}
            </RibbonContainerInvoiceEdit>
        </InvoiceDocument>
    );

    // Mobile stuff
    const [mobileInvoiceEdit, setMobileInvoiceEdit] = useState(editable);

    useEffect(() => {
        setMobileInvoiceEdit(editable);
    }, [editable]);

    const mobileInvoiceEditComponent = (
        <>
            <div style={{ margin: '20px 32px' }}>
                {/* The progress bar on mobile */}
                {showProgressBar && (
                    <InvoiceFillProgressStatus
                        invoice={invoice}
                        invoiceErrors={invoiceErrors}
                        invoiceSendingStatus={invoiceSendingStatusData?.invoiceStatus}
                        isNew={isNew}
                        variant="determinate"
                    />
                )}
            </div>
            {/* The invoice fill wizard on mobile */}
            {invoiceFillWizard}
        </>
    );

    const mobileEditSwitch = (
        <div>
            <Tools
                style={{ margin: '3px 18px' }}
                header={
                    <ToolsHeader
                        titleTransKey={
                            mobileInvoiceEdit
                                ? t('invoice.header-show-invoice')
                                : t('invoice.header-edit-invoice')
                        }
                        style={{
                            color: COLOR_BLUM,
                            fontWeight: 600,
                        }}
                    >
                        <EezyButton
                            className="edit-button"
                            onClick={() => setMobileInvoiceEdit(!mobileInvoiceEdit)}
                            color="important"
                            style={{
                                backgroundColor: COLOR_BLUM,
                                border: `1px solid ${COLOR_WHITE_WALKER}`,
                                color: COLOR_WHITE_WALKER,
                            }}
                        >
                            {mobileInvoiceEdit ? t('general.show') : t('general.edit')}
                        </EezyButton>
                    </ToolsHeader>
                }
            />
            <Line2
                style={{
                    margin: '20px 18px 0',
                    opacity: 0.4,
                }}
            />
        </div>
    );

    const mobileInvoicePreview = (
        <InvoiceDocument
            editable={false}
            invoice={invoice}
            invoiceBrief={invoiceBrief}
            invoiceErrors={invoiceErrors}
            handleInvoiceUpdate={handleInvoiceUpdate}
            handleInvoiceItemUpdate={updateInvoiceItem}
            loadingRecipient={props.loadingRecipient}
            template={selectedTemplate}
            fillType={fillType}
            hasContract={props.hasContract}
        >
            {/* Attachment and costinvoice ribbon */}
            <RibbonContainerInvoiceEdit>
                {invoice?.files && invoice?.files?.length > 0 && (
                    <AttachmentRibbon onClick={() => scrollToAttachmentAt(0)}>
                        {t('invoice.attachments-count', {
                            count: invoice?.files.length,
                        })}
                    </AttachmentRibbon>
                )}
                {invoice?.costInvoice && (
                    <CostRibbon onClick={scrollToCostInvoice}>{t('costs.breakdown-short')}</CostRibbon>
                )}
            </RibbonContainerInvoiceEdit>
        </InvoiceDocument>
    );

    return (
        <>
            {/* Filltype and close-button */}
            <StatusBar
                editable={editable}
                fadeOff={fillHelperOpen}
                fillType={fillType}
                invoice={invoice}
                loading={loadingUpdate || loadingItemUpdate || loadingDocument}
            />
            <PurpleBackground fadeOff={fillHelperOpen}>
                <InvoiceBackground className={fillHelperOpen ? 'withFillHelper' : ''}>
                    <div className="content">
                        {/* MAIN INVOICE AREA */}
                        <div className="content-left">
                            {invoice?.isGroupInvoice && (
                                <GroupInvoiceInfoBox
                                    invoice={invoice}
                                    isOwner={!groupInvoiceCoworker}
                                    handleAddExpenses={handleCreateTempCostInvoice}
                                    handleAddCoworkers={() => setParticipantsModalOpen(true)}
                                    hideButton={
                                        (!groupInvoiceCoworker &&
                                            invoice.status !== 'incomplete' &&
                                            invoice.status !== 'turned_back') ||
                                        (groupInvoiceCoworker && !!invoice.costInvoice)
                                    }
                                />
                            )}
                            {/* Main invoice document */}
                            {!groupInvoiceCoworker && (
                                <>
                                    <Hidden mdDown>
                                        {/* Main invoice document on desktop */}
                                        {invoiceDocument}
                                    </Hidden>
                                    {/* Mobile */}
                                    <Hidden mdUp>
                                        {/* Invoice editor switch */}
                                        {mobileEditSwitch}
                                        {mobileInvoiceEdit
                                            ? /* Invoice editor */
                                            mobileInvoiceEditComponent
                                            : /* Invoice preview */
                                            mobileInvoicePreview}
                                    </Hidden>
                                </>
                            )}
                            {/* Cost invoice document */}
                            {invoice && invoice?.costInvoice && (
                                <>
                                    <span ref={costInvoiceScrollRef} />
                                    <Hidden mdUp>
                                        <CostManualMobile language={props.lang} />
                                    </Hidden>
                                    <CostDocument
                                        editable={true}
                                        invoice={invoice}
                                        invoiceStatus={invoice.status}
                                        sendingLoading={loadingCostInvoice}
                                        onCostInvoiceSend={handleCostInvoiceSend}
                                        openModalsForSalaryAlreadyPaid={{
                                            salaryPaidEarlierModalOpen:
                                                setCostInvoiceNotEditablePaidEarlierModalOpen,
                                            salaryPaidJustModalOpen: setCostInvoiceNotEditableModalOpen,
                                        }}
                                        hasContract={props.hasContract}
                                    />
                                </>
                            )}
                            {/* INVOICE ATTACHMENTS UNDER INVOICE */}
                            {invoice?.files && (
                                <>
                                    <InvoiceAttachments
                                        deleteLoading={attachmentDeleteLoading}
                                        editable={editable}
                                        files={invoice.files}
                                        handleFileRemoval={handleFileRemoval}
                                        invoiceId={invoice.id}
                                        refs={attachmentScrollRefs}
                                    />
                                </>
                            )}
                        </div>
                        {/* Sidebar for desktop */}
                        <Hidden mdDown>
                            <div style={{ width: '100%' }}>
                                {showProgressBar && (
                                    <InvoiceFillProgressStatus
                                        invoice={invoice}
                                        invoiceErrors={invoiceErrors}
                                        invoiceSendingStatus={invoiceSendingStatusData?.invoiceStatus}
                                        isNew={isNew}
                                        variant="determinate"
                                    />
                                )}
                                {fillType === 'guided' && invoiceFillWizard}
                                {fillType !== 'guided' && (
                                    <>
                                        {editable ? (
                                            <FreeFillPanel />
                                        ) : invoice ? (
                                            <InvoiceCompletedPanel
                                                editable={editable}
                                                handleAddCostInvoice={handleCreateTempCostInvoice}
                                                invoice={invoice}
                                                isCoworkerInvoice={groupInvoiceCoworker}
                                                hasContract={props.hasContract}
                                            />
                                        ) : null}
                                    </>
                                )}
                                <div style={{ margin: '36px 32px' }}>
                                    {/* Show switcher if invoice is editable */}
                                    {editable && (
                                        <ToolsHeader
                                            color={COLOR_BLACKWATER}
                                            disabled={invoice?.status === 'sending_pending'}
                                            style={{
                                                fontSize: '15px',
                                                fontWeight: 600,
                                                textTransform: 'initial',
                                            }}
                                            titleTransKey="invoice.fill-type-use-guided"
                                        >
                                            <SwitchButton
                                                color="default"
                                                checked={fillType === 'guided'}
                                                disabled={invoice?.status === 'sending_pending'}
                                                onChange={() => handleFillTypeChange(fillType || 'guided')}
                                                inputProps={{
                                                    'aria-label': t('invoice.fill-type-use-guided') || '',
                                                }}
                                            />
                                        </ToolsHeader>
                                    )}
                                    <ChatTools
                                        recruitmentCode={props.recruitmentCode}
                                        userId={props.userId}
                                        invoiceId={invoice?.id}
                                    />
                                </div>
                                {invoice?.costInvoice && (
                                    <div id="cost-manual">
                                        <CostManualDesktop language={props.lang} />
                                    </div>
                                )}
                            </div>
                        </Hidden>
                    </div>
                </InvoiceBackground>
            </PurpleBackground>
            {/* Modals */}
            {editable && typeModalOpen && (
                <InvoiceTypeModal
                    handleInvoiceUpdate={handleInvoiceUpdate}
                    handleModalClose={() => setTypeModalOpen(false)}
                    invoice={invoice}
                    isOpen={typeModalOpen}
                    lang={props.lang}
                    selectedTemplate={selectedTemplate}
                />
            )}
            {editable && sendModalOpen && invoice && (
                <InvoiceSendModal
                    handleSend={handleSend}
                    handleModalClose={() => setSendModalOpen(false)}
                    invoice={invoice}
                    isOpen={sendModalOpen}
                />
            )}
            {invoice && editable && (
                <DeleteInvoice
                    invoiceId={invoice.id}
                    open={deleteModalOpen}
                    toggleOpen={(open: boolean) => setDeleteModalOpen(open)}
                />
            )}
            {fillHelperOpen && FillHelperContent}
            {costInvoiceNotEditableModalOpen && (
                <InformationModal
                    id="add-cost-paid-info"
                    isOpen={costInvoiceNotEditableModalOpen}
                    onClose={() => {
                        setCostInvoiceNotEditableModalOpen(false);
                    }}
                    title={'costs.warning.salary-already-paid-title'}
                    texts={[
                        'costs.warning.salary-already-paid-send',
                        'costs.warning.salary-already-paid-reach-out',
                    ]}
                />
            )}
            {costInvoiceNotEditablePaidEarlierModalOpen && (
                <InformationModal
                    id="add-cost-paid-earlier-info"
                    isOpen={costInvoiceNotEditablePaidEarlierModalOpen}
                    onClose={() => {
                        setCostInvoiceNotEditablePaidEarlierModalOpen(false);
                    }}
                    title="costs.warning.salary-already-paid-title"
                    texts={[
                        'costs.warning.salary-already-paid-return',
                        'costs.warning.salary-already-paid-return-2',
                    ]}
                />
            )}

            {participantsModalOpen && (
                <ParticipantsModal
                    invoice={props.invoice}
                    handleModalClose={() => setParticipantsModalOpen(false)}
                    isOpen={participantsModalOpen}
                    userName={props.userName}
                    userId={props.userId}
                    language={props.lang}
                />
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        alertAction: (payload: IAlertAction) => {
            dispatch(alertAction(payload));
        },
    };
};

export default connect(null, mapDispatchToProps)(InvoiceView);
